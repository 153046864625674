import React, { useEffect, useState } from 'react';
import {
  Card,
  Icon,
  CardHeader,
  FlexBox,
  Text,
  List, ListItemStandard, ShellBar, Avatar, Input, ShellBarItem
} from '@ui5/webcomponents-react';
import listIcon from "@ui5/webcomponents-icons/dist/list.js";
import tableViewIcon from "@ui5/webcomponents-icons/dist/table-view.js";
import { Post } from "../entity/Post";
import { DataSource } from "typeorm";
import { log } from "util";




export default function About() {

let post = new Post();
let postsss = [ post ];
let postssddds = [ new Post() ];

  // const [posts, setPosts] = useState<Post[]>();
  const [posts, setPosts] = useState<Post[]>([]);
  let responseClone;


  useEffect(() => {
    fetch(
      "https://api.anembok.eu/api.php"
      // {
      //   method: "GET",
      //   headers: {"Content-type": "application/json"},
      // }
    )

      .then((response) => response.json())

      .then((data) => {
        console.log(data);


        setPosts(data);

        postsss = data;

        console.log(postsss.map((value, index) => value));

      console.log(postsss?.length);
        postsss?.map((value, index) => console.log(value.id));
        postsss?.map((value, index) => console.log(value.first_name));
        postsss?.map((value, index) => console.log(value.last_name));


      })
      .catch((err) => {
        console.log(err.message);
        console.log("ERRRRRROR");
      });
  }, []);


  return (
    <>

      {/*<FlexBox*/}
      {/*  className="sap-margin-large"*/}
      {/*  alignItems="Center"*/}
      {/*  direction="Row"*/}
      {/*  justifyContent="SpaceAround"*/}
      {/*  wrap="NoWrap"*/}
      {/*  style={{*/}
      {/*    width: "300px",*/}
      {/*    verticalAlign: 'top',*/}
      {/*  }}*/}
      {/*>*/}
      <Card
        className={"sap-margin-small"}
        header={
          <CardHeader
            additionalText="3 of 5"
            avatar={<Icon name="person-placeholder"/>}
            subtitleText="Direct Reports"
            titleText="TeamSpace"/>}
        style={{
          width: "300px",
          verticalAlign: 'top',
        }}
      >
        <List>
          <ListItemStandard description="Software Architect">
            Richard Wilson
          </ListItemStandard>
          <ListItemStandard description="Visual Designer">
            Elena Petrova
          </ListItemStandard>
          <ListItemStandard description="Quality Specialist">
            John Miller
          </ListItemStandard>
        </List>
      </Card>

      <Card
        className={"sap-margin-small"}
        header={
          <CardHeader
            titleText="Stock Prices"
            subtitleText={`Click here to switch to`}
            interactive

            avatar={
              <Icon

              />
            }
          />
        }
        // style={{ width: "300px", padding: ".sap-padding-x-large" }}
        style={{width: "300px"}}
      >
        <Text>"jhjhjhjhjh"</Text>
      </Card>
      <Card
        className={"sap-margin-small"}
        header={
          <CardHeader
            titleText="Progress"
            subtitleText="List"
            avatar={<Icon name={listIcon}/>}
          />
        }
        style={{width: "300px"}}
      >

      </Card>
      <Card
        className={"sap-margin-small"}
        header={
          <CardHeader
            titleText="AnalyticalTable"
            avatar={<Icon name={tableViewIcon}/>}
          />
        }
        style={{maxWidth: "900px"}}
      >
      </Card>
      {/*</FlexBox>*/}

    </>
  );
}

function componentDidMount() {
  throw new Error('Function not implemented.');
}

