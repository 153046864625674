export const PathConstants = {
  HASH: "/#",
  DASHBOARD: "/",
  ABOUT: "/about",
  HOME: "/home",
  CONTACT: "/contact",
  RESUME: "/resume"
}

export const PathConstantsHash = {
  HOME: PathConstants.HASH + PathConstants.HOME
}